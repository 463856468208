// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-details-tsx": () => import("./../src/templates/details.tsx" /* webpackChunkName: "component---src-templates-details-tsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blogs-tsx": () => import("./../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-booking-tsx": () => import("./../src/pages/booking.tsx" /* webpackChunkName: "component---src-pages-booking-tsx" */),
  "component---src-pages-canceled-tsx": () => import("./../src/pages/canceled.tsx" /* webpackChunkName: "component---src-pages-canceled-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-copyright-js": () => import("./../src/pages/copyright.js" /* webpackChunkName: "component---src-pages-copyright-js" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-photography-tsx": () => import("./../src/pages/photography.tsx" /* webpackChunkName: "component---src-pages-photography-tsx" */),
  "component---src-pages-store-tsx": () => import("./../src/pages/store.tsx" /* webpackChunkName: "component---src-pages-store-tsx" */),
  "component---src-pages-success-tsx": () => import("./../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-videography-tsx": () => import("./../src/pages/videography.tsx" /* webpackChunkName: "component---src-pages-videography-tsx" */)
}

