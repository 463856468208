export interface InitialState {
  isDarkMode: boolean
  cart: Cart[]
}

export interface Cart {
  priceId: string
  quantity: number
  size: string
}

const initialState: InitialState = {
  isDarkMode: false,
  cart: [],
}

// Types
const TOGGLE_DARKMODE = 'TOGGLE_DARKMODE'
const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART'
const NORMALIZED_PRODUCTS = 'NORMALIZED_PRODUCTS'

// Actions
export const toggleDarkMode = (isDarkMode: boolean) => ({
  type: TOGGLE_DARKMODE,
  isDarkMode,
})

export interface AddProduct {
  priceId: string
  price?: number
  quantity?: number
  size?: string
  description?: string
}

export const addProductToCart = (payload: AddProduct) => {
  return { type: ADD_PRODUCT_TO_CART, payload }
}

export const normalizedProducts = (payload: any) => {
  return { type: NORMALIZED_PRODUCTS, payload }
}

// Reducers
export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_DARKMODE:
      return { ...state, isDarkMode: action.isDarkMode }
    case ADD_PRODUCT_TO_CART:
      return {
        ...state,
        // cart: state.cart.map(item =>
        //   item.priceId === action.payload.priceId
        //     ? { ...item, quantity: item.quantity + 1 }
        //     : action.payload
        cart: [...state.cart, action.payload],
      }
    case NORMALIZED_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      }
    default:
      return state
  }
}
